import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BCrumbs from '../components/BCrumbs'
import circuitsImage from '../assets/images/circuits.jpg'
import HeaderGeneric from '../components/HeaderGeneric'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`

  return (
    <Layout>
      <Helmet title={`Digital Tumbleweed: Tags ${tagHeader}`} />

      <div id="main">
        <img src={circuitsImage} alt="" className="header" />

        <BCrumbs crumbs={[{ path: '/blog', title: 'Blog' }]} />

        <HeaderGeneric title={tagHeader} />

        <section id="content" className="main">
          <ul>
            {edges.map(({ node }) => {
              const { path, title } = node.frontmatter
              return (
                <li key={path}>
                  <a href={path} title={title}>
                    {title}
                  </a>
                </li>
              )
            })}
          </ul>

          {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
          <a href="/tags">All tags</a>
        </section>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
